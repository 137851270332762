import BoxRatio from '@swe/shared/ui-kit/components/box-ratio';
import Image, { ImgProps } from '@swe/shared/ui-kit/components/image';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import { useCallback } from 'react';

import styles from './styles.module.scss';

import { ProductImages } from 'common/components/discount-image/components/product-images';
import { Discount } from 'entities/discount/discount';

const DISCOUNT_IMAGE_RATIO = 5.6;
const DISCOUNT_IMAGE_MOBILE_RATIO = 1750 / 750;

type DiscountImageProps = Partial<Pick<Discount, 'bannerUrl' | 'mobileBannerUrl' | 'productImages'>> & {
  isMobile?: boolean;
  alt: string;
  title?: string;
  width: number;
} & ComponentHasClassName &
  Pick<ImgProps, 'fit' | 'lazy'>;

const DiscountImage = ({
  bannerUrl,
  mobileBannerUrl,
  productImages,
  isMobile,
  fit = 'contain' as const,
  className,
  alt,
  title,
  width,
  lazy,
}: DiscountImageProps) => {
  const image = isMobile ? mobileBannerUrl : bannerUrl;
  const ratio = isMobile ? DISCOUNT_IMAGE_MOBILE_RATIO : DISCOUNT_IMAGE_RATIO;
  const hasProductImages = productImages && productImages.length > 0;

  const altFn = useCallback((idx: number) => `${alt} ${idx + 1}`, [alt]);

  if (image || !hasProductImages) {
    return (
      <Image
        src={image}
        ratio={ratio}
        className={className}
        fit={fit}
        alt={alt}
        title={title}
        width={width}
        lazy={lazy}
      />
    );
  }

  return (
    <BoxRatio
      ratio={ratio}
      className={className}
    >
      <div className={styles.container}>
        <ProductImages
          width={width}
          images={productImages}
          className={styles.productImages}
          makeAlt={altFn}
          title={title}
          lazy={lazy}
        />
      </div>
    </BoxRatio>
  );
};

export { DiscountImage };
export type { DiscountImageProps };
export default DiscountImage;
