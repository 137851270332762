import Image from '@swe/shared/ui-kit/components/image';
import { ComponentHasClassName } from '@swe/shared/ui-kit/types/common-props';

import cx from 'clsx';

import styles from './styles.module.scss';

type ProductImagesProps = {
  images: Image[];
  makeAlt(idx: number): string;
  title?: string;
  width: number;
  lazy?: boolean;
} & ComponentHasClassName;

const ProductImages = ({ images, className, makeAlt, title, width, lazy }: ProductImagesProps) => {
  const maxThreeImages = images.slice(0, 3);

  return (
    <div className={cx(styles.root, className, maxThreeImages.length === 2 && styles._two)}>
      {maxThreeImages.map((image, imageIdx) => (
        <div
          className={styles.imageCard}
          key={imageIdx}
        >
          <Image
            className={styles.image}
            src={image}
            ratio={1}
            alt={makeAlt(imageIdx)}
            title={title}
            width={width && width / 3}
            lazy={lazy}
          />
        </div>
      ))}
    </div>
  );
};

export { ProductImages };
export type { ProductImagesProps };
export default ProductImages;
